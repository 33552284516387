<template>
    <div class="entry-metadata">
        <BaseTag
            v-if="showOnlyForMembers && !userHasAccess && !isFenexOnly(entry)"
            class="entry-metadata__lock"
            data-background="darkblue"
            data-icon="true"
        >
            <BaseIcon icon="lock" />
            <span>voor leden</span>
        </BaseTag>

        <FenexTag
            v-if="entry.isFenex"
            :show-lock="!userHasAccess"
            data-icon="true"
        />

        <slot name="pre" />

        <slot>
            <!-- <DossierTags
                v-if="!!dossierTags && dossierTags.length > 0"
                :dossiers="dossierTags"
                :should-link-dossiers="shouldLinkDossiers"
                :show-dropdown="showDossierDropdown"
            /> -->

            <BaseTag
                v-if="entry.contentType && entry.contentType[0]?.title"
                class="entry-metadata__type"
            >
                <span>
                    {{ entry.contentType[0]?.title }}
                </span>
            </BaseTag>
        </slot>

        <slot name="extra" />

        <time
            v-if="entry.postDate && showPostDate"
            class="entry-metadata__date"
            :datetime="entry.postDate"
        >
            {{ formatDate(entry.postDate) }}
        </time>
    </div>
</template>

<script setup>
import BaseTag from '@/components/BaseTag/BaseTag.vue';
import { formatDate } from '~/utils/date.js';
import { useAuthStore } from '~/store/auth';
import { hasAccess, isFenexOnly } from '~/utils/access';
import { computed } from 'vue';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: () => {}
    },
    showPostDate: {
        type: Boolean,
        default: true
    },
    showDossierDropdown: {
        type: Boolean,
        default: true
    },
    shouldLinkDossiers: {
        type: Boolean,
        default: true
    },
    showOnlyForMembers: {
        type: Boolean,
        default: true
    }
});

// const dossierTags = computed(() => {
//     return props.entry?.dossiers ?? [];
// });

const userHasAccess = computed(() => {
    const user = useAuthStore().user;

    return hasAccess(props.entry, user?.contentPermissions);
});
</script>

<style lang="less" src="./EntryMetadata.less" />
