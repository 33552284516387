<template>
    <BaseCardNew
        v-if="entry.windexTitle && entry.windexUrl"
        :entry="entry"
        :title="truncate(entry.windexTitle, 75)"
        :uri="agendaUrl"
        :data-product-type="entry.windexProductCategory"
        class="agenda-card"
    >
        <template
            v-if="date"
            #image
        >
            <div class="agenda-card__date">
                <div class="agenda-card__month-day">{{ day }} {{ formattedMonth }}</div>
                <div class="agenda-card__year">{{ formattedYear }}</div>
            </div>
        </template>

        <template #meta>
            <div class="agenda-card__location-meta">
                <div
                    v-if="location"
                    class="agenda-card__location"
                >
                    <BaseIcon
                        icon="location"
                        class="agenda-card__icon"
                    />
                    <span class="agenda-card__location__text">{{ location }}</span>
                </div>

                <div
                    v-if="locationAddress"
                    class="agenda-card__location"
                >
                    <BaseIcon
                        icon="map"
                        class="agenda-card__icon"
                    />

                    <span class="agenda-card__location__text">{{ locationAddress }}</span>
                </div>
            </div>

            <EntryMetadata
                :show-only-for-members="false"
                :show-post-date="false"
                :show-dossier-dropdown="false"
                :should-link-dossiers="false"
                :entry="entry"
            >
                <BaseTag v-if="entry.windexProductCategory">
                    {{ entry.windexProductCategory }}
                </BaseTag>
            </EntryMetadata>
        </template>
    </BaseCardNew>
</template>

<script setup>
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';
import BaseCardNew from '@/components/BaseCardNew/BaseCardNew.vue';
import BaseIcon from '@/components/BaseIcon/BaseIcon.vue';
import truncate from '@/utils/truncate';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    }
});

const location = computed(() => {
    if (!props.entry?.windexLocation) {
        return;
    }

    return props.entry?.windexLocation.toLowerCase();
});

const locationAddress = computed(() => {
    if (!props.entry?.windexLocationAddress) {
        return;
    }

    return props.entry?.windexLocationAddress.toLowerCase();
});

const date = computed(() => {
    return props.entry?.windexDate ?? null;
});

const day = computed(() => {
    if (!toValue(date)) {
        return;
    }

    return format(new Date(toValue(date)), 'dd', { locale: nl });
});

const formattedMonth = computed(() => {
    if (!toValue(date)) {
        return;
    }

    const formattedMonthName = format(new Date(toValue(date)), 'MMM', {
        locale: nl
    });

    return formattedMonthName.replace('.', '');
});

const formattedYear = computed(() => {
    if (!toValue(date)) {
        return;
    }

    const yearString = format(new Date(toValue(date)), 'y', {
        locale: nl
    });

    return yearString;
});

const agendaUrl = `/bijeenkomsten/${props.entry.slug}/${props.entry.id}`;
</script>

<style lang="less" src="./AgendaCard.less" />
