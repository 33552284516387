<template>
    <NuxtLink
        v-if="entry && title && to"
        :to="to"
        class="base-card"
        :class="{
            'base-card--inline': !isList,
            'base-card--list': isList
        }"
        :data-has-access="userHasAccess"
    >
        <div class="base-card__container">
            <div
                v-if="
                    [
                        'pages_default_Entry',
                        'tools_default_Entry',
                        'articles_default_Entry',
                        'trainings_default_Entry',
                    ].includes(entry.__typename)
                        && image
                "
                class="base-card__image"
            >
                <div
                    v-if="!userHasAccess"
                    class="base-card__lock"
                >
                    <BaseTag data-background="darkblue">
                        <BaseIcon icon="lock" />

                        <span>Alleen voor leden</span>
                    </BaseTag>
                </div>

                <BaseImage
                    :image="image"
                    :width="image.width"
                    :height="image.height"
                    :alt="image.alt"
                    sizes="(max-width: 992px) 248px, 410px"
                />
            </div>

            <div class="base-card__body">
                <div class="base-card__content">
                    <h4
                        v-if="title"
                        class="base-card__title"
                    >
                        {{ title }}
                    </h4>

                    <div
                        v-if="!!$slots.description"
                        class="base-card__description"
                    >
                        <slot name="description" />
                    </div>
                </div>

                <div
                    v-if="!!$slots.meta"
                    class="base-card__meta"
                >
                    <slot name="meta" />
                </div>
            </div>

            <div
                v-if="
                    ![
                        'pages_default_Entry',
                        'tools_default_Entry',
                        'articles_default_Entry',
                        'trainings_default_Entry'
                    ].includes(entry.__typename)
                        && (image || !!$slots.image)
                "
                class="base-card__image"
            >
                <div
                    v-if="!userHasAccess"
                    class="base-card__lock"
                >
                    <BaseIcon icon="lock" />
                </div>

                <slot name="image">
                    <BaseImage
                        :image="image"
                        :width="image.width"
                        :height="image.height"
                        :alt="image.alt"
                        sizes="(max-width: 992px) 248px, 410px"
                    />
                </slot>
            </div>
        </div>
    </NuxtLink>
</template>

<script setup>
import BaseImage from '@/components/BaseImage/BaseImage.vue';
import { toRefs, computed } from 'vue';
import { useAuthStore } from '~/store/auth';
import { hasAccess } from '~/utils/access';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: null
    },
    alt: {
        type: String,
        required: false,
        default: ''
    },
    uri: {
        type: String,
        default: null
    },
    title: {
        type: String,
        default: null
    },
    isList: {
        type: Boolean,
        default: false
    }
});

const { featuredImage } = toRefs(props.entry);
const image = featuredImage?.value?.[0];

const userHasAccess = computed(() => {
    const user = useAuthStore().user;

    return hasAccess(props.entry, user?.contentPermissions);
});

const to = computed(() => {
    if (props.uri) {
        return props.uri;
    }

    if (props.entry?.uri) {
        return `/${props.entry.uri}`;
    }

    return null;
});

const title = computed(() => {
    if (props.title) {
        return props.title;
    }

    if (props.entry?.title) {
        return props.entry.title;
    }

    return null;
});
</script>

<style lang="less" src="./BaseCardNew.less" />
