<template>
    <main
        class="hlaml"
    >
        <div
            v-if="!!$slots.hero"
            class="hlaml__header"
        >
            <slot name="hero" />
        </div>

        <div
            v-if="!!$slots.default"
            class="hlaml__body"
        >
            <slot />
        </div>

        <div
            v-if="!!$slots.contentBlocks"
            class="hlaml__body hlaml__content-blocks"
        >
            <slot name="contentBlocks" />
        </div>

        <div
            v-if="!!$slots.cta || !!footerCallToActionBlock"
            class="hlaml__cta"
        >
            <slot name="cta">
                <HlamlCtaSection
                    :title="footerCallToActionBlock.sectionTitle"
                    :rich-text="footerCallToActionBlock.richText"
                    :call-to-action="footerCallToActionBlock.callToAction"
                    :secondary-call-to-action="footerCallToActionBlock.secondaryCallToAction"
                    :images="footerCallToActionBlock.images"
                />
            </slot>
        </div>
    </main>
</template>

<script setup>
const props = defineProps({
    entry: {
        type: Object,
        default: () => {},
        required: false
    }
});

const { entry } = toRefs(props);

const footerCallToActionBlock = computed(() => {
    if (!entry) {
        return;
    }

    return entry.value?.footerCta?.[0];
});
</script>

<style lang="less">
:root {
    --diagonal-height: 25px;

    @media @q-md-min {
        --diagonal-height: 50px;
    }
}

.hlaml__body,
.hlaml__cta {
    position: relative;
    z-index: 11; // Higher than regular navigation bar (10) but lower than footer (12)
}

.hlaml__body {
    background: var(--color-lightest);
    padding-top: 2rem;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: var(--diagonal-height);
        background: var(--color-lightest);
        clip-path: polygon(100% 0, 0 100%, 100% 100%);
        transform: translateY(-100% + 1px);
    }
}

.hlaml__content-blocks {
    padding-bottom: calc(6.25rem);

    &:before {
        content: unset;
    }
}
</style>
