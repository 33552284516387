<template>
    <Teleport to="body">
        <div
            v-if="showIosInstallPwaMessage && !dismissed"
            class="install-pwa install-pwa--ios"
        >
            <section>
                <span class="install-pwa__tln-logo">
                    <img
                        src="~/assets/img/logo.svg"
                        alt="Logo"
                        loading="eager"
                        fetchpriority="high"
                        width="70"
                        height="44"
                    >
                </span>

                <BaseButton
                    data-size="small"
                    class="install-pwa__dismiss base-button--clean base-button--transparent-light base-button--icon-only"
                    @click="dismiss"
                >
                    <template #icon>
                        <BaseIcon icon="close" />
                    </template>
                </BaseButton>

                <div class="install-pwa__context">
                    <label>INSTALLEER TLN WEBAPP</label>
                    Klik op
                    <BaseIcon
                        class="install--pwa__icon-ios-share"
                        icon="ios-share"
                    />
                    en dan 'Zet op beginscherm'
                </div>
            </section>
        </div>


        <div
            v-else-if="deferredPrompt && !isInStandaloneMode && !dismissed && isAndroid"
            class="install-pwa install-pwa--android"
        >
            <section>
                <BaseButton
                    data-size="small"
                    class="install-pwa__dismiss base-button--clean base-button--transparent-light base-button--icon-only"
                    @click="dismiss"
                >
                    <template #icon>
                        <BaseIcon icon="close" />
                    </template>
                </BaseButton>

                <div class="install-pwa__flex">
                    <span class="install-pwa__tln-logo">
                        <img
                            src="~/assets/img/logo.svg"
                            alt="Logo"
                            loading="eager"
                            fetchpriority="high"
                            width="70"
                            height="44"
                        >
                    </span>

                    <div class="install-pwa__context">
                        <label>INSTALLEER TLN WEBAPP</label>

                        Voeg de TLN webapp toe aan je startscherm
                    </div>
                </div>


                <BaseButton
                    :size="'large'"
                    class="pwa-install__submit base-button--primary"
                    @click="clickCallback"
                >
                    <template #default>
                        installeer
                    </template>

                    <template #icon>
                        <BaseIcon icon="install" />
                    </template>
                </BaseButton>
            </section>
        </div>
    </Teleport>
</template>

<script setup>
const deferredPrompt = ref(null);
const isIos = ref(false);
const isAndroid = ref(false);
const showIosInstallPwaMessage = ref(false);
const isInStandaloneMode = ref(true);
const dismissed = ref(false);
const LOCAL_STORAGE_KEY_DISMISS = 'install-pwa-dismissed';
const LOCAL_STORAGE_VALUE_DISMISS = 'yes';

function handleBrowserEvent(event) {
    event.preventDefault();

    deferredPrompt.value = event;
}

async function clickCallback() {
    if (deferredPrompt) {
        // Show the prompt
        deferredPrompt.value.prompt();
        // Wait for the user to respond to the prompt
        await deferredPrompt.value.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                dismiss();
            }

            deferredPrompt.value = null;
        });
    }
}

function dismiss() {
    dismissed.value = true;
    localStorage.setItem(LOCAL_STORAGE_KEY_DISMISS, LOCAL_STORAGE_VALUE_DISMISS);
}

onBeforeMount(() => {
    isInStandaloneMode.value = 'standalone' in window.navigator && window.navigator.standalone;
    isIos.value = /iphone|ipad|ipod/.test(window.navigator.userAgent.toLocaleLowerCase());
    isAndroid.value = /android/.test(window.navigator.userAgent.toLocaleLowerCase());

    if (localStorage.getItem(LOCAL_STORAGE_KEY_DISMISS) === LOCAL_STORAGE_VALUE_DISMISS) {
        dismissed.value = true;
    }

    if (isIos.value && !isInStandaloneMode.value) {
        showIosInstallPwaMessage.value = true;
    }
});

onMounted(() => {
    window.addEventListener('beforeinstallprompt', handleBrowserEvent);
});

onBeforeUnmount(() => {
    window.removeEventListener('beforeinstallprompt', handleBrowserEvent);
});
</script>

<style lang="less">
.install-pwa {
    position: fixed;
    inset: 0;
    display: flex;
    align-items: flex-end;
    z-index: 2147483632;
    pointer-events: none;
}

.install-pwa section {
    position: relative;

    display: flex;
    column-gap: 1rem;
    width: 100%;
    padding: 1rem;

    background-color: var(--color-lighterblue);
    color: var(--color-darkest);
    pointer-events: auto;
}

.install-pwa--android section {
    flex-direction: column;
}

.install-pwa--android .install-pwa__flex {
    display: flex;
    column-gap: 1rem;
}

.install-pwa .install-pwa__context label {
    .typography-label-large();

    display: block;
    margin-bottom: .5rem;
}

.install-pwa .install--pwa__icon-ios-share {
    font-size: 1.125rem;
    transform: translateY(.25rem);
}

.install-pwa .install-pwa__context {
    font-size: .875rem;
}

.install-pwa__tln-logo {
    display: flex;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    background-color: var(--color-lightest);
    outline: 1px solid rgba(27, 51, 124, .07);
    border-radius: 8px;
}

.install-pwa__tln-logo img {
    width: 1.59375rem;
    height: 1rem;
    object-fit: contain;
    margin: .40625rem auto .59375rem auto;
}

button.dn-button.base-button.pwa-install__submit {
    margin-top: 1rem;
    margin-left: auto;

    font-size: .875rem;
}

button.dn-button.base-button.install-pwa__dismiss {
    position: absolute;
    right: 0;
    top: 0;

    background-color: var(--color-darkblue-05);
}

@media @q-md-min {
    .install-pwa {
        display: none;
    }
}
</style>
