<template>
    <ul
        class="overview-grid"
        :class="{ 'overview-grid--list': isList }"
        :data-content-type="
            entries && entries.length > 0
                ? entries[0].__typename
                : ''
        "
    >
        <li
            v-for="entry in entries"
            :key="`entry-${entry.id}`"
        >
            <component
                :is="componentType(entry)"
                :entry="entry"
                :is-list="isList"
                :is-inline="entry.__typename === 'pages_default_Entry' ? true : false"
            >
                <!-- TODO: remove debug info when all card types are supported -->
                <pre v-if="isUnknownEntryType(entry)">{{ entry }}</pre>
            </component>
        </li>
    </ul>
</template>

<script setup>
import { resolveComponent } from 'vue';

defineProps({
    entries: {
        type: Array,
        default: () => []
    },
    isList: {
        type: Boolean,
        default: false
    }
});

// Prefix the component name with 'Lazy' to make it a lazy component
const entryTypeToComponentMap = {
    'articles_default_Entry': resolveComponent('LazyArticleCard'),
    'tools_default_Entry': resolveComponent('LazyToolCard'),
    'trainings_default_Entry': resolveComponent('LazyTrainingCard'),
    'themesAndDossiers_dossier_Entry': resolveComponent('LazyDossierCard'),
    'agenda_deelopleiding_Entry': resolveComponent('LazyAgendaCard'),
    'agenda_bijeenkomst_Entry': resolveComponent('LazyAgendaCard'),
    'pages_default_Entry': resolveComponent('LazyPageCard')
};

const isUnknownEntryType = entry => !(entry.__typename in entryTypeToComponentMap);

const componentType = (entry) => {
    if (!isUnknownEntryType(entry)) {
        return entryTypeToComponentMap[entry.__typename];
    }

    console.warn('Unknown entry type', entry.__typename);
    return 'div';
};
</script>

<style lang="less" src="./OverviewGrid.less"></style>
