<template>
    <BaseCardNew
        :entry="entry"
        :is-inline="isInline"
        class="training-card"
    >
        <template #meta>
            <div
                v-if="
                    trainingDates
                        && trainingDates.length > 0
                        && (
                            trainingDates.length !== 1
                            || trainingDates[0].windexDate
                        )
                "
                class="training-card__meta__date-and-location"
            >
                <div>
                    <BaseIcon icon="calender" />

                    <span class="training-card__meta__date-and-location__text">
                        {{
                            trainingDates.length > 1
                                ? 'Meerdere datums'
                                : format(trainingDates[0].windexDate, 'd MMMM y', { locale: nl })
                        }}
                    </span>
                </div>

                <div v-if="trainingDates && trainingDates.length > 0">
                    <BaseIcon icon="map" />

                    <span class="training-card__meta__date-and-location__text">
                        {{
                            trainingDates.length > 1
                                ? 'Meerdere locaties' // trainingDates.map((trainingDate) => trainingDate.windexLocation).join(' ')
                                : trainingDates[0].windexLocation
                        }}
                    </span>
                </div>
            </div>

            <EntryMetadata
                :entry="entry"
                :should-link-dossiers="false"
                :show-post-date="false"
                :show-dossier-dropdown="false"
            >
                <template #pre>
                    <BaseTag>TLN Opleiding</BaseTag>
                </template>
            </EntryMetadata>
        </template>
    </BaseCardNew>
</template>

<script setup>
import BaseCardNew from '@/components/BaseCardNew/BaseCardNew.vue';
import BaseTag from '@/components/BaseTag/BaseTag.vue';
import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

const props = defineProps({
    entry: {
        type: Object,
        required: true,
        default: null,
    },
    isInline: {
        type: Boolean,
        default: false,
    }
});

const trainingDates = computed(() =>
    props.entry.featuredOpleidingEntries.length > 0
        && props.entry.featuredOpleidingEntries[0].children.length > 0
        ? props.entry.featuredOpleidingEntries[0].children
        : null
);
</script>

<style lang="less" src="./TrainingCard.less" />
